import cx from 'classnames';
import { FC } from 'react';

import styles from './RenderImageFrameScrollableContent.module.css';

export interface RenderImageFrameScrollableContentProps {
  isFullscreen?: boolean;
  isResponsiveEnabled?: boolean;
}

const RenderImageFrameScrollableContent: FC<
  RenderImageFrameScrollableContentProps
> = ({ children, isFullscreen, isResponsiveEnabled }) => {
  return (
    <div
      className={cx(styles.renderer, {
        [styles.fullScreenRenderer]: isFullscreen,
        [styles.responsiveRenderer]: isResponsiveEnabled,
      })}
    >
      {children}
    </div>
  );
};

export default RenderImageFrameScrollableContent;
