/**
 * @see {@link https://storylane.atlassian.net/l/cp/xE1nPWV1 Docs}
 */
export enum CrossOriginSessionEventEvent {
  ProjectResolved = 'demo_open',
  WidgetReady = 'step_rendered',
  WidgetChanged = 'step_view',
  WidgetCtaClick = 'primary_cta',
  WidgetSecondaryClick = 'secondary_cta',
  FlowChanged = 'flow_start',
  FlowEnd = 'flow_end',
  PageChanged = 'page_view',
  LeadCaptured = 'lead_identify',
  OpenedExternalUrl = 'open_external_url',
  FlowlistItemClick = 'checklist_item_view',
  DemoFinished = 'demo_finished',
  RecordingStart = 'demo_recording_start',
  RecordingStop = 'demo_recording_stop',
  CustomCta = 'convert_cta',
}

export const CrossOriginSessionEventMessage = 'storylane-demo-event';

export const CrossOriginTokenSubmitMessage = 'storylane-token-submit';
export const CrossOriginIdentifyUserMessage = 'storylane-identify-user';
export const CrossOriginCookieConsentMessage = 'storylane-cookie-consent';
export const CrossOriginEmbedDemoEvent = 'storylane-embed-demo';
export const CrossOriginDemoHostSource = 'storylane-demo-host';
export const CrossOriginHostInfoEvent = 'storylane-host-info';
export const CrossOriginTrackingSource = 'storylane-tracking';
